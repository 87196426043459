import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover, PopoverBody } from 'reactstrap'
import NavigationMenu from './NavigationMenu'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import { t } from '$themelocalization'
import './CategoriesNavbar.scss'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'

const CategoriesNavbar = ({ categoriesTree }) => {
  const [hoveredItem, setHoveredItem] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [isHover, setIsHover] = useState(null)
  const [lastHover, setLastHover] = useState(null)

  if (!(categoriesTree && categoriesTree.length > 0)) {
    return null
  }

  const onMouseEnter = (category, target) => {
    queueMicrotask(() => {
      setSelectedCategory(category)
      setHoveredItem(document.getElementById(`id-${category}`))
    })
  }

  const onMouseLeave = () => {
    setSelectedCategory(null)
    setHoveredItem(null)
  }

  //get extra menu items here
  const hoverMouseOver = (item,no) => {
    if (!item) { return }
    setIsHover('true');
    setLastHover('custMenu'+no);
  }
  const hoverMouseOut = () => {
    setIsHover('false');
    setLastHover('');
  }
    const addMenuPosition = getVariableValue('--cust-menu-position', 'End');
    const addMenu1text = getVariableValue('--cust-menu1-text', '');
    const addMenu1url = getVariableValue('--cust-menu1-url', '');
    const addMenu2text = getVariableValue('--cust-menu2-text', '');
    const addMenu2url = getVariableValue('--cust-menu2-url', '');
    const addMenu3text = getVariableValue('--cust-menu3-text', '');
    const addMenu3url = getVariableValue('--cust-menu3-url', '');
    let addLink1;
    if (addMenu1text.length > 0 && addMenu1url.substring(0,4).toLowerCase() === 'http') {
      addLink1 = <a className='category-title-wrapper' href={addMenu1url}><span id='custMenu1' className={isHover && lastHover === 'custMenu1' ? 'category-title highlight' : 'category-title'} onMouseOver={() => hoverMouseOver(addMenu1text, '1')} onMouseOut={() => hoverMouseOut()}><span className='link'>{addMenu1text}</span></span></a>
    }
    let addLink2;
    if (addMenu2text.length > 0 && addMenu2url.substring(0,4).toLowerCase() === 'http') {
      addLink2 = <a className='category-title-wrapper' href={addMenu2url}><span id='custMenu2' className={isHover && lastHover === 'custMenu2' ? 'category-title highlight' : 'category-title'} onMouseOver={() => hoverMouseOver(addMenu2text, '2')} onMouseOut={() => hoverMouseOut()}><span className='link'>{addMenu2text}</span></span></a>
    }
    let addLink3;
    if (addMenu3text.length > 0 && addMenu3url.substring(0,4).toLowerCase() === 'http') {
      addLink3 = <a className='category-title-wrapper' href={addMenu3url}><span id='custMenu3' className={isHover && lastHover === 'custMenu3' ? 'category-title highlight' : 'category-title'} onMouseOver={() => hoverMouseOver(addMenu3text, '3')} onMouseOut={() => hoverMouseOut()}><span className='link'>{addMenu3text}</span></span></a>
    }

  return (<div className="categories-navbar">
    <div className="category-title-wrapper view-show-all" onMouseEnter={(e) => onMouseEnter(0, e.target)} onMouseLeave={onMouseLeave}>
          <span className={`category-title ${selectedCategory === 0 ? 'highlight' : ''}`} id="id-0">
            {t('Header.All_Categories')}
          </span>
      <span className="category-spacer"></span>
      {
        hoveredItem && selectedCategory === 0 &&
        <Popover className="" fade={false} isOpen={true} placement="bottom-start"
                 target={hoveredItem} container={hoveredItem} popperClassName="categories-navbar-popper">
          <PopoverBody>
            <NavigationMenu categoriesTree={categoriesTree} viewShowAll={true} selectedCategory={null}/>
          </PopoverBody>
        </Popover>
      }
    </div>
    {addMenuPosition.toLowerCase() === 'start' && addLink1}
    {addMenuPosition.toLowerCase() === 'start' && addLink2}
    {addMenuPosition.toLowerCase() === 'start' && addLink3} 
    {
      categoriesTree.map(({ Category, SubCategories }, i) => {
        const { FriendlyID, Name } = Category
        return <Link className="category-title-wrapper"
                     onMouseEnter={(e) => onMouseEnter(FriendlyID)}
                     onMouseLeave={onMouseLeave}
                     key={i}
                     to={urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })}>
                <span className={`category-title ${selectedCategory === FriendlyID ? 'highlight' : ''}`}
                      key={i} id={`id-${FriendlyID}`}>
                  <span className="link" key={i} dangerouslySetInnerHTML={{ __html: Name }}/>
                </span>
          {
            hoveredItem && selectedCategory === FriendlyID && SubCategories?.length > 0 &&
            <Popover fade={false} isOpen={true} placement="bottom-start"
                     target={hoveredItem} container={hoveredItem} popperClassName="categories-navbar-popper">
              <PopoverBody>
                <NavigationMenu categoriesTree={categoriesTree} viewShowAll={false} selectedCategory={Category}/>
              </PopoverBody>
            </Popover>
          }
        </Link>
      })
    }
    {addMenuPosition.toLowerCase() !== 'start' && addLink1}
    {addMenuPosition.toLowerCase() !== 'start' && addLink2}
    {addMenuPosition.toLowerCase() !== 'start' && addLink3}
  </div>)
}

export default CategoriesNavbar
